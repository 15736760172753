import { mapGetters } from "vuex"
import debounce from "lodash/debounce"

import data_table_source from "./IsDataTableSource"
import ui_utilities from "./UiUtilities"

const is_remote_data_table_source = {
    mixins: [data_table_source, ui_utilities],
    created () {
        this.refresh = debounce(this.refresh, 300)
    },
    methods: {
        pre_load () {},
        post_load (response) {
            // Anything to do after the current page's data has been loaded?
            if(this.page_autoscroll) this.page_autoscroll()
        },
        refresh (force = false) {
            if(!this.entity) console.error(this.$root.translate("IsRemoteDataTableSource has no entity defined."))

            this.pre_load()

            if(this.can_refresh) {
                const query = {
                    ...this.state,
                    fields: this.fields,
                    relation_ids: this.relation_ids
                }

                const new_query = JSON.stringify(query)
                const changed = (force === true) || (this.current_query !== new_query)
                if(!changed) return
                this.current_query = new_query

                this.loading = true
                this.loading_data_version = this.data_version
                return this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: this.entity,
                        query
                    }
                ).then(response => {
                    if(this.loading_data_version === this.data_version) {
                        if(!this.is_infinite || (this.state.page === 1)) {
                            this.current_records_ids = response.record_ids
                        } else {
                            response.record_ids.forEach(record_id => this.current_records_ids.push(record_id))
                            // this.current_records_ids = this.current_records_ids.concat(response.record_ids)
                        }
                        this.data_version++

                        this.set_state_values({
                            total: response.total || 0,
                            found: response.found || 0
                        })

                        this.post_load(response)
                    }
                }).catch(error => {
                    this.$error(error.message)
                    if(!this.data_version) this.data_version = 1
                }).then(() => {
                    this.loading = false
                })
            }
            return Promise.resolve()
        },
        reset () {
            this.reset_state()
            /* this.$store.dispatch("JANITOR", { entities: [this.entity, ...this.relation_ids] }) * NO: a remote table source is not high enough in the hierarchy to assume they own all the store data ; Janitor really should only be called on route change - in the Page component */
            this.refresh()
        },
        page_rows (rows) {
            return rows
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            return rows
        },
        search_rows (rows) {
            return rows
        },
        filter_by_selection () {
            this.filtered_by_selection = true
            this.current_records_ids = [...this.selected_row_ids]
            this.reset_state(true)
            this.set_state_values({
                page: 1,
                found: this.selected_row_ids.length
            })
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        can_refresh () {
            return this.is_public_page || this.profile_id
        },
        rows () {
            return this.entity_records(this.entity, this.current_records_ids)
        },
        fields () {
            return Object.keys(this.columns)
        },
        export_url () {
            const url = new URL(`https://${window.document.location.hostname}/export/${this.entity}`)

            const search = []
            Object.keys(this.state).forEach((key) => {
                if((!key.match(/^per_page$/i)) && this.state[key]) search.push(`${key}=${encodeURIComponent(this.state[key])}`)
            })

            url.search = search.join("&")
            return url.href
        }
    },
    data () {
        return {
            // NEEDS TO BE OVERRIDEN
            entity: "",
            relation_ids: [],

            loading: false,
            loading_data_version: 0,
            data_version: 0,
            current_query: "",

            current_records_ids: [],
            is_infinite: false
        }
    }
}

export default is_remote_data_table_source
