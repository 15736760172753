import { mapGetters } from "vuex"

import handles_saving from "./HandlesSaving"
import profile_utilities from "./ProfileUtilities"
import ui_utilities from "./UiUtilities"
import confirms from "./Confirms"

const is_record_editor = {
    mixins: [handles_saving, profile_utilities, ui_utilities, confirms],
    mounted () {
        this.reload()
    },
    watch: {
        profile_id: "reload",
        $route: "reload"
    },
    methods: {
        grab_record_id () {
            const int_record_id = parseInt(this.$route.params.id)
            this.record_id = (`${int_record_id}` === this.$route.params.id) ? int_record_id : this.$route.params.id
        },
        post_load () {
            // local data tables stats, etc......
        },
        reload (reset = false) {
            if(!this.entity) console.error(this.$root.translate("IsRecordEditor has no entity defined."))

            if(this.can_reload) {
                if(this.cleanup_before_reload) this.$store.dispatch("JANITOR", { entities: [this.entity, ...this.relation_ids] })

                this.grab_record_id()

                this.waiting = true
                if(this.record_id) {
                    this.$store.dispatch(
                        "FETCH_RECORD",
                        {
                            entity: this.entity,
                            id: this.record_id,
                            query: this.reload_query
                        }
                    ).then(() => {
                        this.shell_record = null
                        this.post_load()
                    }).catch(error => {
                        this.$error(error.message)
                    }).then(() => {
                        this.waiting = false
                    })
                } else {
                    this.$store.dispatch(
                        "FETCH_RECORD_SHELL",
                        { entity: this.entity }
                    ).then(record => {
                        this.shell_record = record
                        this.post_load()
                    }).catch(error => {
                        this.$error(error.message)
                    }).then(() => {
                        this.waiting = false
                    })
                }
            }
        },
        save (value, field) {
            if(this.edited_record) {
                if(this.edited_record[field] !== value) this.edited_record[field] = value
                if(this.edited_record.id) return this.save_field(this.edited_record[field], field)
            }
            return Promise.resolve()
        },
        record_save () {
            const edited_record = this.edited_record
            const is_new = !edited_record.id
            if(is_new) this.creating_record = true
            return this.save_data_for_record_id(this.entity, edited_record.id || 0, edited_record).then(record => {
                this.creating_record = false
                if(is_new && record) this.$router.replace({ name: `${this.entity}.edit`, params: { id: record.uuid || record.id } })
            })
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete Record"),
                    message: this.$root.translate("Do you really want to delete this record? There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        record_delete () {
            this.deleting_record = true
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: this.entity,
                    id: this.record_id
                }
            ).then(record => {
                this.record_deleted(record)
                this.done_confirming()
            }).catch(this.receive_error).then(() => {
                this.deleting_record = false
            })
        },
        record_deleted (record) {
            console.log("Record deleted", record)
        }
    },
    computed: {
        ...mapGetters(["entity_record", "entity_records"]),
        can_reload () {
            return this.is_public_page || this.profile_id
        },
        cleanup_before_reload () {
            return true
        },
        reload_query () {
            return { relation_ids: this.relation_ids }
        },
        edited_record () {
            if(this.shell_record) return this.shell_record
            if(!this.entity || !this.record_id) return null
            return this.entity_record(this.entity, this.record_id)
        }
    },
    data () {
        return {
            waiting: false,
            // NEEDS TO BE OVERRIDEN
            entityName: "record:::records",
            relation_ids: [],
            shell_record: null,
            creating_record: false,
            deleting_record: false
        }
    }
}

export default is_record_editor
