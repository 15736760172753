const handles_dates = {
    methods: {
        standardized_date_range_label (range, date_filters = {}, default_value = "") {
            if(!range || (!range.from && !range.to)) return default_value

            if(range.from) range.from = this.$dayjs(range.from)
            if(range.to) range.to = this.$dayjs(range.to)

            date_filters = {
                long: "ddd MMM D, YYYY",
                medium: "MMM D, YYYY",
                short: "MMM D",
                ...date_filters
            }

            if(!range.from && !!range.to) return range.to.format(date_filters.long)
            if(!range.to && !!range.from) return range.from.format(date_filters.long)

            if(range.from.isSame(range.to, "day")) return range.from.format(date_filters.long)
            if(range.from.isSame(range.to, "year")) return `${range.from.format(date_filters.short)} - ${range.to.format(date_filters.medium)}`
            return `${range.from.format(date_filters.medium)} - ${range.to.format(date_filters.medium)}`
        },
        standardized_range_object (date_data) {
            if((typeof date_data === "object") && (typeof date_data.from !== "undefined")) {
                date_data.from = this.$dayjs(date_data.from)
                date_data.to = this.$dayjs(date_data.to)
                return date_data
            }
            const date = this.$dayjs(date_data)
            return { from: date, to: date }
        },
        standardized_date (date_data) {
            if(!date_data) return null
            return this.$dayjs(date_data)
        },
        is_upcoming_date (date, unit = "minute") {
            return this.$dayjs(date).isSameOrAfter(this.$dayjs(), unit)
        },
        is_past_date (date, unit = "minute") {
            return this.$dayjs(date).isBefore(this.$dayjs(), unit)
        }
    }
}

export default handles_dates
