import is_page from "./IsPage"
import is_record_editor from "./IsRecordEditor"

const is_record_editor_page = {
    mixins: [is_page, is_record_editor],
    computed: {
        page_title () {
            const record = this.edited_record
            const entity_name = this.$root.translate(this.entityName, {}, 1)
            if(!record || !record.id) return this.$root.translate(`New ${entity_name}`)
            return record.name || record.title || record.email || entity_name
        }
    }
}

export default is_record_editor_page
